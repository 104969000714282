import { useState } from 'react'
import styles from './SearchWidget.module.scss'

interface Props {
  search: string
  setSearch: (value: string) => void
  includeNew: boolean
  setIncludeNew: (value: boolean) => void
}

export function SearchWidget({
  search,
  setSearch,
  includeNew,
  setIncludeNew,
}: Props) {
  const [localSearch, setLocalSearch] = useState(search)

  return (
    <div className={styles.searchFormContainer}>
      <form
        id="searchForm"
        className={styles.searchForm}
        onSubmit={(e) => {
          e.preventDefault()
          setSearch(localSearch)
        }}
      >
        <div className={styles.searchBox}>
          <svg
            height="30px"
            width="30px"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
          <input
            id="search"
            type="text"
            placeholder="Type a destination name..."
            value={localSearch}
            onChange={(e) => {
              e.preventDefault()
              setLocalSearch(e.target.value)
            }}
          />
        </div>
        <button type="submit">Search</button>
        <label>
          <input
            id="added"
            type="checkbox"
            checked={includeNew}
            onChange={(e) => {
              e.preventDefault()
              setIncludeNew(e.target.checked)
            }}
            name="added"
          />
          Include New Sources
        </label>
      </form>
    </div>
  )
}
