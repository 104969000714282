import { UserStateProvider } from './api/auth-state'
import { AppMain } from './AppMain'
import 'react-responsive-modal/styles.css';

function App() {
  return (
    <UserStateProvider>
      <AppMain />
    </UserStateProvider>
  )
}

export default App
