import styles from './AppMain.module.scss'
import ChangesTableWithBoundary from './changes-table/ChangesTableBoundary'
import { useUserState } from './api/auth-state'
import { Login } from './login/Login'
import { Header } from './layout/Header'

export function AppMain() {
  const {
    userState: { haveToken },
  } = useUserState()
  console.log('App: haveToken', haveToken)
  return (
    <>
      {!haveToken && <Login />}
      {haveToken && (
        <div className={styles.app}>
          <div className={styles['app-container']}>
            <Header />
            <main>
              <ChangesTableWithBoundary />
            </main>
          </div>
        </div>
      )}
    </>
  )
}
