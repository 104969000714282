import { Change } from '../api/Change'
import { pick } from 'lodash'
import { Writeable } from './Writeable'
import { strToDate } from './strToDate'

export interface ChangeRow
  extends Pick<
    Writeable<Change>,
    '_id' | 'sourceId' | 'type' | 'destinations' | 'comment' | 'status'
  > {
  source: ChangeRowSource
  receivedAt: Date | null
  previousVersionReceivedAt: Date | null
  revisitAt: Date | null
  diff: DiffCellData
}

export interface ChangeRowSource {
  title: Change['sourceTitle']
  url: Change['sourceUrl']
  urlAlias: string
}

/**
 * The diff cell needs to receive a field with lots of data, in order to be able to show the diff modal
 */
export interface DiffCellData {
  type: Change['type']
  changeId: Change['_id']
  source: ChangeRowSource
  receivedAt: Date | null
  previousVersionReceivedAt: Date | null
  numWordsAdded: Change['numWordsAdded']
  numWordsRemoved: Change['numWordsRemoved']
}

export function toChangeRow(value: Change): ChangeRow {
  const receivedAt = strToDate(value.receivedAt)
  const previousVersionReceivedAt = strToDate(value.previousVersionReceivedAt)
  const source = {
    title: value.sourceTitle,
    url: value.sourceUrl,
    urlAlias: getUrlAlias(value.sourceUrl),
  }

  return {
    ...pick(
      value,
      '_id',
      'sourceId',
      'type',
      'destinations',
      'comment',
      'status',
      'revisitAt'
    ),
    source,
    receivedAt,
    previousVersionReceivedAt,
    revisitAt: strToDate(value.previousVersionReceivedAt),
    diff: {
      type: value.type,
      numWordsAdded: value.numWordsAdded,
      numWordsRemoved: value.numWordsRemoved,
      receivedAt,
      previousVersionReceivedAt,
      source,
      changeId: value._id,
    },
  }
}

function getUrlAlias(url: string) {
  return getPathLastPart(url.replace(/\.(?:html|asp|js)/, '')).replace(
    /^www\./,
    ''
  )
}

function getPathLastPart(u: string) {
  const url = new URL(u)
  const pathname = url.pathname.replace(/\/$/, '')
  const parts = pathname.split('/')
  const last = parts[parts.length - 1]
  if (last && !/^index\.?.*/.test(last) && 'en' !== last) {
    return last
  }
  const beforeLast = parts[parts.length - 2]
  if (beforeLast) {
    return beforeLast
  }
  return url.host
}
