import { format } from 'date-fns'

export function TableDateCell({ value }: { value: string }) {
  if (!value) {
    return ''
  }
  try {
    return format(new Date(value), 'yyyy-MM-dd')
  } catch (e) {
    console.log('failed to parse date value', value)
    return ''
  }
}

export function TableDateTimeCell({ value }: { value: string }) {
  if (!value) {
    return ''
  }
  try {
    return format(new Date(value), 'yyyy-MM-dd HH:mm')
  } catch (e) {
    console.log('failed to parse date value', value)
    return ''
  }
}
