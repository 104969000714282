import { Endpoint, Resource, schema } from '@rest-hooks/rest'
import { fetchJson } from './api-endpoint'
import { getFetchInitWithAuth } from './get-fetch-init-with-auth'

export type IsoDateString = string // Just so we'll know it's a date

export type ChangeType = 'added' | 'changed' | 'removed'

export interface Destination {
  name: string
  code: string
}

export type ChangeStatus = 'New' | 'In review' | 'Revisit Later' | 'Done'

export class Change extends Resource {
  readonly _id: string = ''
  readonly newContentId: string = ''
  readonly newSha512Hash: string = ''
  readonly numWordsAdded: number = 0
  readonly numWordsRemoved: number = 0
  readonly oldContentId: string = ''
  readonly oldSha512Hash: string = ''
  readonly previousVersionReceivedAt: IsoDateString | null = null
  readonly receivedAt: IsoDateString | null = null
  readonly sourceId: string = ''
  readonly sourceUrl: string = ''
  readonly sourceTitle: string = ''
  readonly type: ChangeType = 'changed'
  readonly destinations: Destination[] | null = null
  readonly comment: string | null = null
  readonly status: ChangeStatus | null = null
  readonly revisitAt: IsoDateString | null = null

  pk() {
    return this._id
  }

  static get key() {
    return 'change'
  }

  static getFetchInit = getFetchInitWithAuth
}

const fetchChanges = (a: any) => {
  const q: any = {}
  const sortField = a.sort?.[0] ?? { id: 'receivedAt', desc: true }
  if (sortField) {
    q.sort = `${sortField.id}_${sortField.desc ? 'desc' : 'asc'}`
  }
  if (a.includeNew) {
    q.includeNew = true
  }
  if (a.search) {
    q.search = a.search
  }
  if (a.pageNum) {
    q.offset = a.pageNum * a.pageSize
  }
  q.limit = a.pageSize

  const qString = Object.keys(q).length > 0 ? `?${new URLSearchParams(q)}` : ''
  return fetchJson<Change[]>(`/${qString}`)
}
export const getAllChanges = new Endpoint(fetchChanges, {
  schema: new schema.Array(Change),
})
