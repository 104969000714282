import axios from 'axios'
import { getAuthToken } from './auth-state'

export const API_ENDPOINT =
  process.env.REACT_APP_CHANGES_API_ENDPOINT ?? `http://localhost:3000/changes`
console.log('Using API endpoint: ', API_ENDPOINT)

const apiClient = axios.create({ baseURL: API_ENDPOINT })

export async function fetchJson<T>(url: string): Promise<T> {
  const response = await apiClient.get<T>(url, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })
  return response.data
}
