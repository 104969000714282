import React, { useEffect, useState } from 'react'
import {
  Column,
  TableInstance,
  useTable,
  useSortBy,
  HeaderGroup,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
} from 'react-table'
import useSWR from 'swr'

import { useResource } from 'rest-hooks'
import { getAllChanges } from '../api/Change'
import { ChangeRow, toChangeRow } from '../model/ChangeRow'
import styles from './ChangesTable.module.scss'
import { SearchWidget } from './SearchWidget'
import { TableDateCell, TableDateTimeCell } from './TableDateCell'
import { TableDestinationsCell } from './TableDestinationCell'
import { TableDiffCell } from './TableDiffCell'
import { TableSourceCell } from './TableSourceCell'
import { fetchJson } from '../api/api-endpoint'

const PAGE_SIZE = 15

export const changesTableColumns = [
  {
    Header: 'Time',
    accessor: 'receivedAt',
    Cell: TableDateTimeCell,
    headerClassName: styles.timeColumn,
  },
  {
    Header: 'Destination',
    accessor: 'destinations',
    Cell: TableDestinationsCell,
    headerClassName: styles.destinationColumn,
  },
  {
    Header: 'Source',
    accessor: 'source',
    Cell: TableSourceCell,
    headerClassName: styles.sourceColumn,
  },
  {
    Header: '# Words Changed',
    accessor: 'diff',
    Cell: TableDiffCell,
    headerClassName: styles.nWordsColumn,
  },
  {
    Header: 'Revisit At',
    accessor: 'revisitAt',
    Cell: TableDateCell,
    headerClassName: styles.revisitAtColumn,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
  },
] as Column<ChangeRow>[]

interface ChangesTableFetchParams {
  sort: {
    id: keyof ChangeRow
    desc: boolean
  }[]
  search: string
  includeNew?: boolean
}
export const ChangesTable: React.FunctionComponent<{}> = () => {
  const [sort, setSort] = useState<ChangesTableFetchParams['sort']>([])
  const [includeNew, setIncludeNew] = useState(false)
  const [search, setSearch] = useState<ChangesTableFetchParams['search']>('')
  const [fetchPageNum, setFetchPageNum] = useState(0)
  const query: any = {}
  if (search.trim()) {
    query.search = search.trim()
  }
  if (includeNew) {
    query.includeNew = true
  }
  const queryStr = Object.keys(query).length
    ? '?' + new URLSearchParams(query).toString()
    : ''
  const { data: countData } = useSWR<{ count: number }>(
    `/count${queryStr}`,
    fetchJson
  )

  const allChanges = useResource(getAllChanges, {
    sort,
    search,
    includeNew,
    pageSize: PAGE_SIZE,
    pageNum: fetchPageNum,
  })
  const data = React.useMemo(
    (): ChangeRow[] => allChanges.map((c) => toChangeRow(c)),
    [allChanges]
  )
  const tableInstance: TableInstance<ChangeRow> = useTable<ChangeRow>(
    {
      columns: changesTableColumns,
      data,
      manualSortBy: true,
      manualPagination: true,
      pageCount:
        countData !== undefined ? Math.ceil(countData.count / PAGE_SIZE) : 1,
      initialState: { pageIndex: 0, pageSize: PAGE_SIZE },
    } as any,
    useSortBy,
    usePagination
  )
  const {
    state: tableState,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance as unknown as UsePaginationInstanceProps<ChangeRow> & {
    state: UsePaginationState<ChangeRow>
  }
  useEffect(() => {
    setFetchPageNum(pageIndex)
  }, [pageIndex])

  useEffect(() => {
    setFetchPageNum(0)
    gotoPage(0)
  }, [search, gotoPage])

  const sortBy = JSON.stringify((tableState as any)?.sortBy) // must be a string (not an object) so the effect can depend on it
  React.useEffect(() => {
    if (sortBy) {
      setSort(JSON.parse(sortBy))
      setFetchPageNum(0)
    }
  }, [sortBy])

  return (
    <>
      <SearchWidget
        search={search}
        setSearch={setSearch}
        includeNew={includeNew}
        setIncludeNew={setIncludeNew}
      />

      {!allChanges?.length && (
        <div className={styles.noChangesFound}>No changes were found</div>
      )}

      {allChanges?.length > 0 && (
        <>
          <table {...getTableProps()} className={styles.changesTable}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        (column as any).getSortByToggleProps()
                      )}
                      className={(column as any).headerClassName}
                    >
                      {column.render('Header')}
                      <span>{getSortIndicator(column)} </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={(cell.column as any).className}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className={styles.pagination}>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            <span>
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>
          </div>
        </>
      )}
    </>
  )
}

function getSortIndicator(c: HeaderGroup<ChangeRow>) {
  const column = c as any
  return column.isSorted ? (column.isSortedDesc ? ' ↓' : ' ↑') : ''
}
