import { getAuthToken } from './auth-state'

export function getFetchInitWithAuth(init: RequestInit): RequestInit {
  const authToken = getAuthToken()
  if (!authToken) {
    return init
  }
  return {
    ...init,
    headers: {
      ...init.headers,
      'Access-Token': `Bearer ${authToken}`,
    },
  }
}
