import { useCallback, useState } from 'react'
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout,
} from 'react-google-login'
import { useUserState } from '../api/auth-state'
import styles from './Login.module.scss'
import { useGoogleLogin } from 'react-google-login'
import { ReactComponent as GoogleIcon } from './google.svg'

const GOOGLE_CLIENT_ID =
  '951830029059-n4eh53j2uh9937nqsupvpni5l58201cd.apps.googleusercontent.com'

export function Login() {
  const [error, setError] = useState<any | null>(null)
  const { dispatchUserAction } = useUserState()

  const onSuccess = useCallback(
    (r: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      dispatchUserAction({
        type: 'USER/LOGIN_SUCCESS',
        googleLoginResponse: r,
      })
    },
    [dispatchUserAction]
  )

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure: setError,
    clientId: GOOGLE_CLIENT_ID,
    isSignedIn: true,
  })

  return (
    <div className={styles.login}>
      <button onClick={signIn} className={styles.button}>
        <GoogleIcon />
        <span>Sign in with Google</span>
      </button>
      {error && <div>{error.message}</div>}
    </div>
  )
}

export function Logout() {
  const { dispatchUserAction } = useUserState()

  return (
    <GoogleLogout
      clientId={GOOGLE_CLIENT_ID}
      buttonText="Logout"
      onLogoutSuccess={() => {
        dispatchUserAction({ type: 'USER/LOGOUT' })
        console.log('Logged out')
      }}
    ></GoogleLogout>
  )
}

// export function Login() {
//   const [error, setError] = useState<any | null>(null)
//   const { userState, dispatchUserAction } = useUserState()
//   console.log('login - user state', userState)
//   return (
//     <div className={styles.login}>
//       <GoogleLogin
//         clientId="951830029059-n4eh53j2uh9937nqsupvpni5l58201cd.apps.googleusercontent.com"
//         buttonText="Login"
//         uxMode="redirect"
//         onSuccess={(r) => {
//           console.log('google login success', r)
//           dispatchUserAction({
//             type: 'USER/LOGIN_SUCCESS',
//             googleLoginResponse: r,
//           })
//         }}
//         onFailure={(e) => {
//           console.log('google login error', e)

//           setError(e)}}
//         cookiePolicy={'single_host_origin'}
//       />
//       {error}
//     </div>
//   )
// }
