import { ReactNode } from 'react'
import { Row } from 'react-table'
import { ChangeRow, ChangeRowSource } from '../model/ChangeRow'
import styles from './TableSourceCell.module.scss'

export function TableSourceCell({
  value,
}: {
  value: ChangeRowSource
}): ReactNode {
  if (!value) {
    return ''
  }
  return (
    <div className={styles.source}>
      {value.title}
      <span>⸱</span>
      <a
        href={value.url}
        target="blank"
        rel="noopener"
        className={styles.source}
      >
        {value.urlAlias}
      </a>
    </div>
  )
}

export function compareSources(a: Row<ChangeRow>, b: Row<ChangeRow>) {
  const url1 = (a.values as ChangeRow).source.url
  const url2 = (b.values as ChangeRow).source.url
  return url1.localeCompare(url2)
}
