import { Endpoint, Resource } from '@rest-hooks/rest'
import { fetchJson } from './api-endpoint'
import { ChangeType, IsoDateString } from './Change'

export class SourceVersion {
  _id: string = ''
  content: string = ''
  sha512Hash: string = ''
}

export class ChangeDiff extends Resource {
  _id: string = ''
  newContent: SourceVersion = new SourceVersion()
  newContentId: string = ''
  newSha512Hash: string = ''
  numWordsAdded: number = 0
  numWordsRemoved: number = 0
  oldContent: SourceVersion = new SourceVersion()
  oldContentId: string = ''
  oldSha512Hash: string = ''
  previousVersionReceivedAt: IsoDateString | null = null
  receivedAt: IsoDateString | null = null
  sourceId: string = ''
  sourceUrl: string = ''
  type: ChangeType = 'changed'

  pk() {
    return this._id
  }

  static get key() {
    return 'change-diff'
  }
}

export interface ChangeDiffParams {
  id: string
}

const fetchChangeDiffDocument = ({ id }: ChangeDiffParams) =>
  fetchJson<ChangeDiff[]>(`/${id}/diff`)

export const getChangeDiffDocument = new Endpoint(fetchChangeDiffDocument, {
  schema: ChangeDiff,
})
