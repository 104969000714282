import { ChangeRow, DiffCellData } from '../model/ChangeRow'
import { useState } from 'react'
import { Modal } from 'react-responsive-modal'
import styles from './TableDiffCell.module.scss'
import { TableDiffWidget } from '../diff-viewer/TableDiffWidget'
import { Row } from 'react-table'

interface Params {
  value: DiffCellData
}

export function TableDiffCell({ value }: Params) {
  const [showModal, setShowModal] = useState(false)
  if (!value) {
    return null
  }
  const closeModal = () => setShowModal(false)
  const toggleModal = () => {
    setShowModal(!showModal)
  }
  return (
    <div className={styles.tableDiffCell}>
      {value.type === 'added' && (
        <span className={styles.wordsAdded}>New Source</span>
      )}
      {value.type === 'removed' && (
        <span className={styles.wordsRemoved}>Removed</span>
      )}
      {value.type === 'changed' && (
        <span>
          {value.numWordsAdded > 0 && (
            <span className={styles.wordsAdded}>+{value.numWordsAdded}</span>
          )}
          {value.numWordsRemoved > 0 && (
            <span className={styles.wordsRemoved}>
              -{value.numWordsRemoved}
            </span>
          )}
        </span>
      )}
      <button onClick={toggleModal}>Show</button>
      <Modal
        open={showModal}
        onClose={closeModal}
        classNames={{ modal: styles.modal }}
        center
      >
        <TableDiffWidget value={value} close={closeModal} />
      </Modal>
    </div>
  )
}

export function sortDiffs(a: Row<ChangeRow>, b: Row<ChangeRow>) {
  const diff1 = (a.values as ChangeRow).diff
  const diff2 = (b.values as ChangeRow).diff

  // First compare types
  if (diff1.type === 'added' && diff2.type !== 'added') {
    return diff1.type === 'added' ? 1 : -1
  }
  if (diff1.type === 'removed' && diff2.type !== 'removed') {
    return diff1.type === 'removed' ? -1 : 1
  }

  // Then compare by amount of change
  const n1 = diff1.numWordsAdded + diff1.numWordsRemoved
  const n2 = diff2.numWordsAdded + diff2.numWordsRemoved
  return n1 - n2
}
