import { FunctionComponent } from 'react'
import { useUserState } from '../api/auth-state'
import { Logout } from '../login/Login'
import styles from './Header.module.scss'

export const Header: FunctionComponent<{}> = () => {
  const {
    userState: { user },
  } = useUserState()

  return (
    <header className={styles.header}>
      <h1>Tripsguard Content Explorer</h1>

      <div className={styles.userContextArea}>
        {user && (
          <>
            {user?.profilePhoto && (
              <img
                className={styles.profileImage}
                src={user.profilePhoto}
                alt=""
              />
            )}
            <div className={styles.userName}>
              {user?.firstName} {user?.lastName}
            </div>
            <Logout />
          </>
        )}
      </div>
    </header>
  )
}
