import { User } from './api-types'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'

let authToken: string | null = null
export function getAuthToken() {
  return authToken
}

export interface UserState {
  haveToken: boolean
  user: User | undefined
  isLoading: boolean
  needsRefresh: boolean
  authResponse: GoogleLoginResponse | null
}

export interface UserStateWithToken extends UserState {
  jwt: string
}

export const userInitialState: UserState = {
  haveToken: false,
  user: undefined,
  isLoading: true,
  needsRefresh: false,
  authResponse: null,
}

export type UserAction =
  | { type: 'USER/LOADED_FROM_STORAGE'; jwt: string; user: User }
  | { type: 'USER/REFRESHED_FROM_SERVER'; refreshedUser: User }
  | { type: 'USER/FAILED_TO_REFRESH_FROM_SERVER' }
  | { type: 'USER/NOTHING_TO_LOAD_FROM_STORAGE' }
  | {
      type: 'USER/LOGIN_SUCCESS'
      googleLoginResponse: GoogleLoginResponse | GoogleLoginResponseOffline
    }
  | { type: 'USER/LOGOUT' }

export type UserReducer = (
  prevState: UserState,
  action: UserAction
) => UserState

export const userReducer: UserReducer = (
  state: UserState,
  action: UserAction
): UserState => {
  console.log(action)

  switch (action.type) {
    case 'USER/LOADED_FROM_STORAGE':
      authToken = action.jwt
      return {
        ...state,
        haveToken: true,
        user: action.user,
        isLoading: false,
        needsRefresh: true,
      }
    case 'USER/REFRESHED_FROM_SERVER':
      return { ...state, user: action.refreshedUser, needsRefresh: false }
    case 'USER/FAILED_TO_REFRESH_FROM_SERVER':
      console.warn('Failed to refresh user from server')
      return { ...state, needsRefresh: false }
    case 'USER/NOTHING_TO_LOAD_FROM_STORAGE':
      return { ...state, isLoading: false }
    case 'USER/LOGIN_SUCCESS':
      const r = action.googleLoginResponse as GoogleLoginResponse

      authToken = r.tokenId
      return {
        ...state,
        isLoading: false,
        haveToken: true,
        user: {
          googleId: r.googleId,
          firstName: r.profileObj.givenName,
          lastName: r.profileObj.familyName,
          email: r.profileObj.email,
          profilePhoto: r.profileObj.imageUrl,
        },
      }
    case 'USER/LOGOUT':
      authToken = null
      return { ...state, user: undefined, isLoading: false, haveToken: false }
    default:
      return state
  }
}

export type UserDispatch = React.Dispatch<React.ReducerAction<UserReducer>>

export interface UserStateAndDispatch {
  userState: UserState
  dispatchUserAction: UserDispatch
}

export const UserContext = createContext<UserStateAndDispatch>({
  userState: userInitialState,
  dispatchUserAction: () => null,
})

interface UserStateProviderProps {
  children: React.ReactNode
}

interface JwtToken {
  id: number
  iat: number
  exp: number
}

const LOCAL_STORAGE_ITEM_NAME = 'tgcms_user'

export const UserStateProvider: React.FC<UserStateProviderProps> = ({
  children,
}: UserStateProviderProps) => {
  const [state, dispatch] = useReducer(userReducer, userInitialState)

  useEffect(() => {
    const authResponse = state.authResponse
    if (authResponse === null) {
      return
    }
    let refreshTiming =
      (authResponse.tokenObj.expires_in || 3600 - 5 * 60) * 1000
    const refreshToken = async () => {
      const newAuthRes = await authResponse.reloadAuthResponse()
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000
      authToken = newAuthRes.id_token
    }
    const interval = setInterval(refreshToken, refreshTiming)
    return () => {
      clearInterval(interval)
    }
  }, [state.authResponse])

  const initFromLocalStore = async () => {
    let fromStorage
    try {
      fromStorage = await window.localStorage.getItem(LOCAL_STORAGE_ITEM_NAME)
    } catch (e: any) {
      console.warn(
        `Failed to access local storage, can't work with user login`,
        e.message
      )
      return
    }
    if (!fromStorage) {
      dispatch({ type: 'USER/NOTHING_TO_LOAD_FROM_STORAGE' })
      return
    }
    let parsed: UserStateWithToken
    try {
      parsed = JSON.parse(fromStorage)
      const { exp } = jwtDecode(parsed.jwt as string) as JwtToken
      if (exp * 1000 < Date.now()) {
        throw new Error(
          `Token has expired. Time: ${new Date()}, expiration: ${new Date(
            exp * 1000
          )}`
        )
      }
    } catch (e: any) {
      console.warn('Failed to use user token from storage', e.message)
      await window.localStorage.removeItem(LOCAL_STORAGE_ITEM_NAME)
      dispatch({ type: 'USER/NOTHING_TO_LOAD_FROM_STORAGE' })
      return
    }
    dispatch({
      type: 'USER/LOADED_FROM_STORAGE',
      jwt: parsed.jwt as string,
      user: parsed.user as User,
    })
  }

  useEffect(() => {
    initFromLocalStore()
  }, [])

  const refreshUser = async () => {
    // const response = await fetch(`${apiUrl}/users/me`, {
    //   headers: { Authorization: `Bearer ${state.jwt}` },
    // })
    // const refreshedUser: User = await response.json()
    // if (refreshedUser?.email) {
    //   dispatch({ type: 'USER/REFRESHED_FROM_SERVER', refreshedUser })
    // } else {
    //   dispatch({ type: 'USER/FAILED_TO_REFRESH_FROM_SERVER' })
    // }
  }

  // const storeToLocalStore = async (newState: UserState) => {
  //   const toStore = pick(newState, 'user')
  //   try {
  //     await window.localStorage.setItem(
  //       LOCAL_STORAGE_ITEM_NAME,
  //       JSON.stringify(toStore)
  //     )
  //   } catch (e: any) {
  //     console.warn(
  //       'Failed to save user-specific homepage to local storage',
  //       e.message
  //     )
  //   }
  // }

  // const cancelPostAuthAction = async () => {
  //   dispatch({ type: 'USER/AUTH_CANCELLED_FOR_ACTION' })
  // }

  useEffect(() => {
    if (state.needsRefresh && state?.haveToken) {
      refreshUser()
    }
  }, [state.needsRefresh, state?.haveToken])

  return (
    <UserContext.Provider
      value={{ userState: state, dispatchUserAction: dispatch }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUserState(): UserStateAndDispatch {
  return useContext(UserContext)
}
