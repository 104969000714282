import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { format } from 'date-fns'
import { DiffCellData } from '../model/ChangeRow'
import styles from './TableDiffWidget.module.scss'
import { useResource } from 'rest-hooks'
import { getChangeDiffDocument } from '../api/ChangeDiff'

interface Props {
  value: DiffCellData
  close: () => void
}
export function TableDiffWidget({ value, close }: Props) {
  const allChanges = useResource(getChangeDiffDocument, { id: value.changeId })

  console.log(value)
  return (
    <>
      <div className={styles.tableDiffCellModal}>
        <header>
          <div>
            <h2>{value.source.title}</h2>
            <a
              target="_blank"
              href={value.source.url}
              rel="noopener noreferrer"
            >
              {value.source.urlAlias}
            </a>
          </div>
        </header>
        <div className={styles.timestamps}>
          <div>
            {value.previousVersionReceivedAt
              ? format(value.previousVersionReceivedAt, `yyyy-MM-dd 'at' hh:mm`)
              : null}
          </div>
          <div>
            {value.receivedAt
              ? format(value.receivedAt, `yyyy-MM-dd 'at' hh:mm`)
              : null}
          </div>
        </div>
        <div className={styles.diffContainer}>
          <ReactDiffViewer
            oldValue={allChanges.oldContent?.content ?? ''}
            newValue={allChanges.newContent?.content ?? ''}
            splitView={true}
            compareMethod={DiffMethod.WORDS}
          />
        </div>
      </div>
    </>
  )
}
