import { ReactNode } from 'react'
import { Row } from 'react-table'
import { Destination } from '../api/Change'
import { ChangeRow } from '../model/ChangeRow'

interface Params {
  value: Destination[]
}

export function TableDestinationsCell({ value }: Params): ReactNode {
  if (!value?.[0]) {
    return null
  }
  try {
    const firstDestination = value[0]?.name?.replace(/\s*\(.*\)/g, '')
    const numAdditionalDestinations = value.length - 1
    return (
      <>
        {firstDestination}
        {numAdditionalDestinations > 0 && (
          <> + {numAdditionalDestinations} more</>
        )}
      </>
    )
  } catch (e) {
    console.log('failed to parse destinations value', value)
    return ''
  }
}

export function compareDestinations(a: Row<ChangeRow>, b: Row<ChangeRow>) {
  const dest1: Destination[] = (a.values as ChangeRow).destinations ?? []
  const dest2: Destination[] = (b.values as ChangeRow).destinations ?? []
  const l = Math.min(dest1?.length, dest2?.length)
  for (let i = 0; i < l; ++i) {
    const diff = dest1[i].name.localeCompare(dest2[i].name)
    if (diff !== 0) {
      return diff
    }
  }
  return dest2.length - dest1.length
}
