import { Suspense, SuspenseProps } from 'react'
import { NetworkError, NetworkErrorBoundary } from 'rest-hooks'
import { ChangesTable } from './ChangesTable'

export default function ChangesTableWithBoundary() {
  return (
    <NetworkErrorBoundaryWithSuspense fallback={<Loading />}>
      <ChangesTable />
    </NetworkErrorBoundaryWithSuspense>
  )
}

interface Props {
  fallback: SuspenseProps['fallback']
  children: React.ReactNode
}

function NetworkErrorBoundaryWithSuspense({ children, fallback }: Props) {
  return (
    <Suspense fallback={fallback}>
      <NetworkErrorBoundary fallbackComponent={ErrorOrLogin}>
        {children}
      </NetworkErrorBoundary>
    </Suspense>
  )
}

function Loading() {
  return <div>Loading content changes...</div>
}

function ErrorOrLogin({ error }: { error: NetworkError }) {
  if (error.status === 401) {
    return <div>login here</div>
  } else if (error) {
    return <div>Unexpected error: {error.message}</div>
  }
  return null
}
